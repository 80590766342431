export default [
  {
    title: '',
    fields: [
      {
        type: 'input',
        inputType: 'text',
        width: '100%',
        name: 'name',
        label: 'Company Name',
        required: true,
      },
      {
        type: 'input',
        inputType: 'number',
        width: '100%',
        name: 'company_number',
        label: 'Company Number',
        required: true,
      },
      {
        type: 'input',
        inputType: 'email',
        width: '100%',
        name: 'email',
        label: 'Email Address',
        required: true,
      },
      {
        type: 'input',
        inputType: 'password',
        width: '100%',
        name: 'password',
        label: 'Password',
        required: true,
        reveal: true,
      },
      {
        type: 'input',
        inputType: 'checkbox',
        width: '100%',
        name: 'terms',
        label:
          'By creating an account I confirm that I have read and agree to the <a href="/about/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="/about/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>',
        required: true,
      },
      {
        type: 'submit',
        width: '100%',
        buttonLabel: 'Create Account',
      },
    ],
  },
];
