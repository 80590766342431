import React, { useContext } from 'react';
import isStrongPassword from 'validator/lib/isStrongPassword';

import registerFields from './data';
import { ERROR_CODE_ACCOUNT_EXISTS, ERROR_MSG_ACCOUNT_EXISTS } from './errors';
import { passwordStrength } from '../PasswordChange/errors';

import { FirebaseContext } from '../Firebase';
import Form from '../Form';
// import StoreData from '../../data/store';

const successMessage =
  'Account created successfully. Please check your email inbox (including junk/spam) to verify your account.';

export default function RegisterForm() {
  const firebase = useContext(FirebaseContext);

  const createAccount = async ({ data, success, error }) => {
    const { name, email, password /* , company_number */ } = data;

    // const isCompany = await StoreData.validateCompany(company_number);

    // if (isCompany.error) {
    //   return error({
    //     message: `You must enter a valid company number to register an account with us. Please try again.`,
    //   });
    // }

    const checkPassword = isStrongPassword(password);

    if (!checkPassword) {
      return error({ message: passwordStrength });
    }

    const {
      doCreateUserWithEmailAndPassword,
      setDatabase,
      user,
      doSendEmailVerification,
    } = firebase;

    const date = new Date().toJSON();

    return doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        const userData = {
          created: date,
          username: name,
          email,
          preferences: {
            newsletter: true,
            notifications: true,
          },
        };
        setDatabase(user(authUser.user.uid), userData);
        return authUser.user;
      })
      .then(user => {
        return doSendEmailVerification(user);
      })
      .then(() => {
        success(successMessage);
      })
      .catch(err => {
        if (err.code === ERROR_CODE_ACCOUNT_EXISTS) {
          err.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        error(err);
      });
  };
  return (
    <Form
      name="register"
      fields={registerFields}
      action={createAccount}
      // style={loginForm}
      // debug
    />
  );
}
